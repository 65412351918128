import React from 'react';
import * as styles from './PersonImage.module.scss';

const PersonImage = (props) => (
  <div
    className={styles.wrapper}
    style={{backgroundImage : `url(${props.img})`}}
  >
    <img src={props.img} className={styles.personImg} alt="" />
  </div>
);

export default PersonImage;