// extracted by mini-css-extract-plugin
export var col1 = "ContactForm-module--col1--APcP9";
export var col2 = "ContactForm-module--col2--Ab6ka";
export var content = "ContactForm-module--content--fHTn2";
export var formSubmit = "ContactForm-module--formSubmit--hRJEV";
export var info = "ContactForm-module--info--HyigZ";
export var inputFile = "ContactForm-module--inputFile--yBxGD";
export var invalid = "ContactForm-module--invalid--XMtVA";
export var invalidSelect = "ContactForm-module--invalidSelect--G2XSM";
export var irrelevant = "ContactForm-module--irrelevant--TULxi";
export var link = "ContactForm-module--link--BVM+h";
export var movingLabel = "ContactForm-module--movingLabel--zqrm7";
export var sent = "ContactForm-module--sent--Y6AYj";
export var wrapper = "ContactForm-module--wrapper--x8I7r";