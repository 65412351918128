import React from 'react';
import DynamicComponent from '../DynamicComponent/DynamicComponent';
import * as styles from './OfferBox.module.scss';

class OfferBox extends DynamicComponent {

  render() {
    return <div className={styles.wrapper}>
      <aside data-aos="fade-right">
        <h3 id={this.props.titleId} style={{backgroundImage : `url(${this.props.img})`}}>{this.props.title}</h3>
      </aside>
      <article data-aos="fade-left" id={this.props.id} dangerouslySetInnerHTML={{__html: this.props.embed}}></article>
    </div>
  }
}

export default OfferBox;