import React from 'react';
import {graphql, useStaticQuery } from 'gatsby';

import DynamicComponent from '../DynamicComponent/DynamicComponent';
import Titles from '../Titles/Titles';
import * as styles from './Team.module.scss';
import { LocPersons, Loc } from '../frontlocdata';

// function TheText() {
//  const data = useStaticQuery(graphql`
//   query {
//       wpPage(databaseId: {eq: 13}) {
//         id
//         content
//       }
//     }
// `)

// console.log(data);
//   return <div dangerouslySetInnerHTML={{__html: data.wpPage.content}}></div>;
// }

class Team extends DynamicComponent {
  render() {  
    return (    
      
      <div
        className={styles.container}
      >
        {console.log(this)}
        <div
          data-aos="fade-right"
          className={styles.wrapper}
        >
          <Titles
            title={LocPersons["team"][Loc]}
            subtitle={LocPersons["meet-us"][Loc]}
          />
          <div className={styles.text} dangerouslySetInnerHTML={{__html: this.props.embed}}></div>        
        </div>
        <div className={styles.bgImage}></div>
      </div>
    )
  }
};

export default Team;

//     <div data-template data-bind:text="team-content" className={styles.text} dangerouslySetInnerHTML={{__html: this.state.content}}>