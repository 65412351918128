import React from 'react';
import Titles from '../Titles/Titles';
import * as styles from './OfferIntro.module.scss';
import { LocOfferIntro, Loc } from '../frontlocdata';

const OfferIntro = (props) => (
  <div
    className={styles.wrapper}
    data-aos="fade-down"
  >
    <Titles
      title={LocOfferIntro["services"][Loc]}
      subtitle={LocOfferIntro["offer"][Loc]}
    />
    <p>{LocOfferIntro["services-intro"][Loc]}</p>
  </div>
); 

export default OfferIntro;