import React from 'react';
import DynamicBatchComponent from '../DynamicComponent/DynamicBatchComponent'
import * as styles from './Reference.module.scss';
import Titles from '../Titles/Titles';
import ItemsCarousel from 'react-items-carousel';
import './Reference.css';
import { LocReference, Loc } from '../frontlocdata';

const noOfItems = 4;
const noOfCards = 1;
//const autoPlayDelay = 15000;
const chevronWidth = 80;

class Reference extends DynamicBatchComponent { 

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => this.setState(prevState => ({
    activeItemIndex: (prevState.activeItemIndex + 1) % (noOfItems-noOfCards + 1),
  }));

  onChange = value => this.setState({ activeItemIndex: value });

  render () {
    return (
      <div className={styles.wrapper}>
        <aside className={styles.titleBox}>
          <Titles
            title={LocReference["credentials"][Loc]}
            subtitle={LocReference["from-other-customers"][Loc]}
          />
        </aside>
        <article className={styles.textBox}>
          <div className={styles.box}>
            {this.state.fetched === '' ?
              <p>{LocReference["loading"][Loc]}</p>
              :
              <ItemsCarousel
                gutter={12}
                numberOfCards={noOfCards}
                activeItemIndex={this.state.activeItemIndex}
                requestToChangeActive={this.onChange}
                rightChevron={<button className="sliderArrowRight">{'>'}</button>}
                leftChevron={<button className="sliderArrowLeft">{'<'}</button>}
                chevronWidth={chevronWidth}
                outsideChevron
              >
                { this.getFetched() && this.getFetched().map((value, index) => (
                  <div className={styles.slideBox} key={index} id={"reference-"+index}>
                    <div className="excerpt" dangerouslySetInnerHTML={{__html: value.excerpt.rendered.replace(/[\xc2\xa0]+/g, ' ')}}></div>
                    <p className={styles.signature+" signature"} dangerouslySetInnerHTML={{__html: value.title.rendered}}></p>
                  </div>
                ))  }
                </ItemsCarousel>
            }
          </div>
        </article>
        <span className={styles.pen}></span>
        <span className={styles.ink}></span>
      </div>
    )
  }
};

export default Reference;