import React from 'react';
import { Tabs, TabLink, TabContent} from 'react-tabs-redux';
import * as styles from './Offices.module.scss';
import { LocOffices, Loc } from '../frontlocdata';

const Offices = () => (
  <div className={styles.wrapper}>

          <div className={styles.officeDesc}>
            <h4>{LocOffices["office-poznan"][Loc]}</h4>
            <p>ul. Literacka 149<br />
            60-461 Poznań<br />
            email: <b><a href="mailto:poznan@leximo.pl">poznan@leximo.pl</a></b><br />
            tel. <b><a href="tel:+48605833663">+48 605 833 663</a></b></p>
          </div>

          <div className={styles.officeDesc}>
            <h4>{LocOffices["office-warsaw"][Loc]}</h4>
            <p>ul. Maszewska 20/50<br />
            01-925 Warszawa<br />
            email: <b><a href="mailto:biuro@leximo.pl">biuro@leximo.pl</a></b><br />
            tel. <b><a href="tel:+48531971555">+48 531 971 555</a></b></p>
          </div>

  </div>
);

export default Offices;