import React, { Component } from 'react';
import PersonTitle from './PersonTitle';
import PersonDesc from './PersonDesc';
import PersonImage from './PersonImage';
import panasImage from '../../assets/anna-panas-galloway.png';
import mazurImage from '../../assets/marcin-mazur.png';
import * as styles from './Persons.module.scss';
import { LocPersons, Loc } from '../frontlocdata';

class Persons extends Component {
  render() {
    return (
      <div className={styles.personsContainer}>
        <div className={styles.wrapper} data-aos="fade-left">
          <PersonTitle
            desc={LocPersons["anna-title"][Loc]}
          >Anna<br />Panas-Galloway</PersonTitle>
          <PersonImage
            img={panasImage}
          />
          <PersonDesc
            embed={this.props.embed[0]}
          />
        </div>
        <div className={styles.wrapper} style={{'borderBottom': '1px solid #d1dadc'}} data-aos="fade-right">
          <PersonTitle
            desc={LocPersons["marcin-title"][Loc]}
          >Marcin Mazur</PersonTitle>
          <PersonImage
            img={mazurImage}
          />
          <PersonDesc
            embed={this.props.embed[1]}
          />
        </div>
      </div>
    )
  }
};

export default Persons;