import React from 'react';
// import PersonTitle from './PersonTitle';
// import PersonDesc from './PersonDesc';
// import PersonImage from './PersonImage';
// import panasImage from '../../assets/anna-panas-galloway.png';
// import mazurImage from '../../assets/marcin-mazur.png';
import * as styles from './PersonTitle.module.scss';

const PersonTitle = (props) => (
  <div className={styles.wrapper}>
    <h3>{props.children}</h3>
    <p id={props.descId}>{props.desc}</p>
  </div>
);

export default PersonTitle;