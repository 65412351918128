import React, { Component } from 'react';
import * as styles from './Number.module.scss';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class Numbers extends Component {
  state = {
    didViewCountUp: false
  };

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({didViewCountUp: true});
    }
  }

  render() {
    return (
      <div className={styles.wrapper} data-aos="fade-up">
        <VisibilitySensor
          onChange={this.onVisibilityChange}
          delayedCall
        >
          <CountUp
            end={this.state.didViewCountUp ? this.props.number : 0}
            className={styles.number}
          />
        </VisibilitySensor>
        <p className={styles.text}>{this.props.text}</p>
      </div>
    )
  }
};

export default Numbers;