import React from 'react';
import Number from './Number';
import * as styles from './Numbers.module.scss';
import { LocNumbers, Loc } from '../frontlocdata';

const Numbers = (props) => (
  <div className={styles.wrapper}>
    <Number
      number="10"
      text={LocNumbers["years-experience"][Loc]}
    />
    <Number
      number="50000"
      text={LocNumbers["translated-pages"][Loc]}
    />
    <Number
      number="150"
      text={LocNumbers["satisfied-customers"][Loc]}
    />
  </div>
);

export default Numbers;