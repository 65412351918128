import React from 'react';
import * as styles from './Diagram.module.scss';
import { LocDiagram, Loc } from '../frontlocdata';
import DynamicComponent from '../DynamicComponent/DynamicComponent';

class Diagram extends DynamicComponent{
  render () {
    return <div className={styles.wrapper}>
      <aside
        className={styles.diagram}
        data-aos="zoom-in"
      >
        <h2>{LocDiagram["we-specialise-in"][Loc]}</h2>
        <p className={styles.tl}>{LocDiagram["law"][Loc]}</p>
        <p className={styles.tr}>{LocDiagram["finance"][Loc]}</p>
        <p className={styles.br}>{LocDiagram["business"][Loc]}</p>
      </aside>
      <article data-aos="fade-left" id={this.props.id} dangerouslySetInnerHTML={{__html: this.props.embed}}></article>
      </div>;    
  }
}
export default Diagram;