import React from 'react';
import * as styles from './Navbar.module.scss';
import Menu from '../Menu/Menu';
import logoImage from '../../assets/logo.png';
import menuIco from '../../assets/menu.svg';
import {isMobile} from 'react-device-detect';
import { animateScroll as scroll } from "react-scroll";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    if (!isMobile) {
      this.state = {isMenuVisible: true};
    } else {
      this.state = {isMenuVisible: false};
    }
  }

  openMenu = () => {
    let isVisible = this.state.isMenuVisible;
    if (isVisible) {
      this.setState({
        isMenuVisible: false,
      });
    } else {
      this.setState({
        isMenuVisible: true,
      });
    }
  }

  scrollToTop = () => {
      scroll.scrollToTop({
          duration: 200,
      }); 
  };

  render() {
    const { isMenuVisible } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <img
            src={logoImage}
            className={styles.logo}
            alt="Leximo"
            onClick={this.scrollToTop}
          />
          <nav>
            <button
              id="menuBtn"
              className={styles.menuBtn}
              onClick={this.openMenu}
            >
              <img src={menuIco} alt="Menu" />
            </button>
            { isMenuVisible &&
              <Menu />
            }
          </nav>
        </div>
      </div>
    )
  }
};

export default Navbar;