import React from 'react';
import DynamicComponent from '../DynamicComponent/DynamicComponent';
import * as styles from './PersonDesc.module.scss';

class PersonDesc extends DynamicComponent {
  render () {
    return (
      <div className={styles.wrapper} id={this.props.id} dangerouslySetInnerHTML={{__html: this.props.embed}}></div>
    );
  }
}

export default PersonDesc;