import React, { Component } from 'react';
import { Link } from "react-scroll";
import { isMobile } from 'react-device-detect';
import * as styles from './Menu.module.scss';
import { LocMenu, Loc } from '../frontlocdata';

  class Menu extends Component {
  hideMenu = () => {
    if (isMobile) {
      let link = document.getElementById('menuBtn');
      link.click();
    }
  };

  render() {

    var otherLangSubDomain = "";
    if(Loc === "pl")
    {
      otherLangSubDomain = "en.";
    }

    return (
      <>
        <ul className={styles.wrapper}>
          <li>
            <Link
              activeClass={styles.active}
              to="team"
              spy={true}
              smooth={true}
              duration={600}
              offset={-100}
              onClick={this.hideMenu}
            >
              {LocMenu["our-team"][Loc]}
            </Link>
          </li>
          <li>
            <Link
              activeClass={styles.active}
              to="services"
              spy={true}
              smooth={true}
              duration={600}
              offset={-100}
              onClick={this.hideMenu}
            >
              {LocMenu["our-services"][Loc]}
            </Link>
          </li>
          <li>
            <Link
                activeClass={styles.active}
                to="credentials"
                spy={true}
                smooth={true}
                duration={600}
                offset={-100}
                onClick={this.hideMenu}
            >
              {LocMenu["credentials"][Loc]}
            </Link>
          </li>
          <li>
            <Link
                activeClass={styles.active}
                to="quote"
                spy={true}
                smooth={true}
                duration={600}
                offset={-100}
                onClick={this.hideMenu}
            >
              {LocMenu["quote"][Loc]}
            </Link>
          </li>
          {/* <li>
            <NavLink
              activeClassName={styles.navItemLinkActive}
              className={styles.navItemLink}
              to="/polityka-prywatnosci">
              Polityka prywatności
            </NavLink>
          </li> */}
          <li>
            <Link
              activeClass={styles.active}
              to="contact"
              spy={true}
              smooth={true}
              duration={600}
              offset={-100}
              onClick={this.hideMenu}
            >
              {LocMenu["contact"][Loc]}
            </Link>
          </li>
        </ul>
        <ul className={styles.language}>
          <li>
            <a href={"https://"+otherLangSubDomain+"leximo.pl"}>
              {LocMenu["other-lang"][Loc]}
            </a>
          </li>
        </ul>
      </>
    )
  }
};

export default Menu;