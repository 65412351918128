import React from '../../../node_modules/react/umd/react.development.js';
import Titles from '../Titles/Titles';
import { Link } from "react-scroll";
import * as styles from './Valuation.module.scss';
import { LocValuation, Loc } from '../frontlocdata';

const Valuation = () => (
  <div className={styles.wrapper}>
    <Titles
      title={LocValuation["quote"][Loc]}
      subtitle={LocValuation["how-much"][Loc]}
    />
    <div className={styles.textBox}>
      <p>{LocValuation["individual-approach"][Loc]}</p>
      <p>{LocValuation["free-quote"][Loc]} <Link
          className={styles.link}
          to="contact"
          smooth={true}
          duration={600}
          offset={-100}
      >biuro@leximo.pl</Link> {LocValuation["or"][Loc]} <Link
          className={styles.link}
          to="contact"
          smooth={true}
          duration={600}
          offset={-100}
          id="fill-form"
      >{LocValuation["fill-form"][Loc]}</Link>.</p>
      <p>{LocValuation["prices-time"][Loc]}</p>
    </div>
  </div>
);

export default Valuation;