import React from 'react';
import Titles from '../Titles/Titles';
import Offices from './Offices';
import ContactForm from './ContactForm';
import * as styles from './Contact.module.scss';
import { LocContact, Loc } from '../frontlocdata';

const Contact = (props) => (
  <>
    <Titles
      title={LocContact["contact"][Loc]}
      subtitle={LocContact["no-barrier"][Loc]}
    />
    <div className={styles.wrapper}>
      <Offices />
      <ContactForm />
    </div>
  </>
);

export default Contact;