import React from 'react';
//import AOS from 'aos';
//import '../../node_modules/aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import CookieBar from '../components/CookieBar/CookieBar';
import Navbar from '../components/Navbar/Navbar';
import Intro from '../components/Intro/Intro';
import Team from '../components/Team/Team';
import Persons from '../components/Persons/Persons';
import Numbers from '../components/Numbers/Numbers';
import OfferIntro from '../components/Offer/OfferIntro';
import OfferBox from '../components/Offer/OfferBox';
import Diagram from '../components/Offer/Diagram';
import Reference from '../components/Reference/Reference';
import Valuation from '../components/Valuation/Valuation';
import Contact from '../components/Contact/Contact';
import Footer from '../components/Footer/Footer';
import tlumaczeniaUwierzytelnioneImage from '../assets/tlumaczenia-uwierzytelnione.png';
import tlumaczeniaZwykleImage from '../assets/tlumaczenia-zwykle.png';
import tlumaczeniaUstneImage from '../assets/tlumaczenia-ustne.png';
import './index.css';
import './App.css';
import { LocIndex, LocMeta, Loc, WordpressMapping } from '../components/frontlocdata';

import {graphql } from 'gatsby';
const ScrollableSection = React.lazy(() => import('react-update-url-on-scroll'));

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
  // 
  // configureAnchors({offset: 130});
}
//AOS.init({disable: 'mobile'});

const HomeView = ( {data} )  => {
  function getNode(container, id) {
    return container.nodes.filter(item => item.databaseId === id)[0]
  }

  function getPageById(id) {
    return getNode(data.allWpPage, id).content;
  }
  
  function getPostById(id) {
    return getNode(data.allWpPost, id);
  }

  function getPage(key) {
    return getPageById(parseInt(WordpressMapping[key][Loc]))
  }
  
  return (
      <div>
        <Helmet>
          <title>{LocMeta["title"][Loc]}</title>
          <meta name="description" content={LocMeta["description"][Loc]} />
        </Helmet>
        <CookieBar />
        <Navbar />
        <Intro />
      { typeof window !== "undefined" && (<React.Suspense fallback="<div></div>">
        <ScrollableSection name={'#team'}>
          <div id="team">            
            <Team embed={getPage('team-content')}/>
            <Persons embed={[getPage('person-anna'), getPage('person-marcin')]}/>
            <Numbers />
          </div>
        </ScrollableSection>
        <ScrollableSection name={'#services'}>
          <div id="services">
            <OfferIntro />
            <OfferBox
              title={LocIndex["translation-certified-title"][Loc]}
              img={tlumaczeniaUwierzytelnioneImage}
              mappingKey="translation-certified"
              embed={getPage('translation-certified')}
              ></OfferBox>
            <OfferBox
              title={LocIndex["translation-regular-title"][Loc]}
              img={tlumaczeniaZwykleImage}
              embed={getPage("translation-regular")}
              ></OfferBox>
            <OfferBox
              title={LocIndex["interpretation-title"][Loc]}
              img={tlumaczeniaUstneImage}
              embed={getPage("interpretation")}
              ></OfferBox>
            <Diagram id="diagram-content" embed={getPage("diagram-content")}>
            </Diagram>
          </div>
        </ScrollableSection>
        <ScrollableSection name={'#credentials'}>
          <div id="credentials">
            <Reference mappingKey="credentials" />
          </div>
        </ScrollableSection>
        <ScrollableSection name={'#quote'}>
          <div id="quote">
            <Valuation />
          </div>
        </ScrollableSection>
        <ScrollableSection name={'#contact'}>
          <div id="contact">
            <Contact />
          </div>
        </ScrollableSection>
      </React.Suspense>)}
        <Footer />
      </div>
    )};

// const App = () => {
//   return (
// <div>
//   <HomeView/>
// </div>
// );
// };


export const query = graphql`
query Post {
  allWpPost {
    nodes {
      databaseId
      content
    }
  }
  allWpPage {
    nodes {
      databaseId
      content
    }
  }
}
`
export default HomeView;